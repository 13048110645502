<template>
    <section class="sider">
        <div class="sider-title">{{ data.title }}</div>
        <ul>
            <li v-for="(item, index) of data.list" :key="index">
                <span>{{ item }}</span>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        data: Object
    }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins";
.sider {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    padding: 30px 15px;
    z-index: $mask-z-index;
    background-color: $color-white;
    &-title {
        font-size: $font-size-l;
    }
}
</style>
